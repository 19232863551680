<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title d-flex flex-column mb-5">
            <h3 class="fw-bolder m-0">
              {{ clientlocationname }}, {{ folderRequest.folderName }}
            </h3>
            <h6 v-if="clientname != ''">[{{ clientname }}]</h6>
          </div>
          <!--end::Card title-->
          <!--begin::Card toolbar-->
          <div class="card-toolbar d-flex flex-column align-items-end">
            <button
              tabindex="3"
              type="submit"
              ref="exportToExcelButton"
              id="exportToExcelBtn"
              class="btn btn-primary"
              @click="exportToExcel()"
              v-if="isExcelFileReady === false"
            >
              <span class="indicator-label">Export To Excel</span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <a
              v-if="isExcelFileReady"
              :href="'/excel/behavioral-surveys/' + excelFileResponse"
              class="btn btn-sm btn-light-primary mt-3"
              download
            >
              Download Excel File
              <img
                style="max-width: 20px"
                src="/media/icons/duotune/files/fil009.svg"
              />
            </a>
          </div>
          <!--end::Card toolbar-->
          <!--begin::Search-->
          <input
            type="text"
            class="form-control form-control-lg form-control-solid px-5 mb-2"
            v-model="searchSurveys"
            name="search"
            placeholder="Search by name, profilename, job title or status..."
            data-kt-search-element="input"
          />
          <!--end::Search-->
        </div>

        <!--begin::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0" v-if="isDataReady">
          <!--begin:: Flex Container-->
          <div
            id="incline-flex-table"
            class="d-flex flex-row flex-wrap pb-5 pt-3"
            v-for="(item, index) in filterSurveys"
            :key="index"
            :clientId="'survey_' + index"
            :class="{
              'incline-flex-table-background': index % 2 === 0,
              green: index % 2 !== 0,
            }"
          >
            <div
              class="d-flex flex-column flex-sm-column flex-fill flex-wrap px-5"
            >
              <p class="incline-flex-table-th mb-0">Name</p>
              <p class="incline-flex-table-td mb-0">
                <strong>{{ item.name }}</strong>
              </p>
              <p class="incline-flex-table-td mb-0" style="font-size: 13px">
                <a :href="'mailto:' + item.email">{{ item.email }}</a>
              </p>
              <p class="incline-flex-table-td mb-0" style="font-size: 13px">
                {{ item.jobTitle }}
              </p>
            </div>
            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th">Profile Name</p>
              <p class="incline-flex-table-td">{{ item.profileName }}</p>
            </div>
            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th">Reports</p>
              <p class="incline-flex-table-td mb-1">
                <a
                  :href="
                    '/pdfs/incline-behavioral-narratives/' +
                    item.behavioralNarrativePdf
                  "
                  target="_blank"
                >
                  <strong>Narrative</strong>
                </a>
                <img
                  style="max-width: 20px"
                  src="/media/icons/duotune/files/fil009.svg"
                />
              </p>
              <p class="incline-flex-table-td mb-1">
                <a
                  :href="
                    '/pdfs/incline-natural-vs-environmental/' +
                    item.naturalVersusEnvironmentalPdf
                  "
                  target="_blank"
                >
                  <strong>Graphs</strong>
                </a>
                <img
                  style="max-width: 20px"
                  src="/media/icons/duotune/files/fil009.svg"
                />
              </p>
              <p class="incline-flex-table-td">
                <a
                  :href="
                    '/pdfs/incline-coaching-guides/' + item.coachingGuidePdf
                  "
                  target="_blank"
                >
                  <strong>Add'l Resources</strong>
                </a>
                <img
                  style="max-width: 20px"
                  src="/media/icons/duotune/files/fil009.svg"
                />
              </p>
            </div>
            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th">Survey Graph</p>
              <p>
                <img
                  style="max-width: 225px"
                  :src="'/survey-result-grids/' + item.graphFilename"
                  alt="Natural Self Grid"
                />
              </p>
            </div>
            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th">Status</p>
              <p v-show="item.surveyStatus == 'completed'">
                <span class="badge badge-success mx-2">Completed</span>
                <br />
                <span class="mx-2">{{ d(item.completedDate) }}</span>
              </p>
              <p>
                <span
                  v-show="item.surveyStatus == 'pending'"
                  class="badge badge-warning mx-2"
                >
                  Pending
                </span>
              </p>
              <p>
                <a
                  href="#"
                  class="btn btn-sm btn-flex btn-light-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_transfer_survey"
                  @click="selectedRow(item.surveyId, item.respondentId)"
                >
                  <span class="svg-icon svg-icon-3">
                    <inline-svg src="/media/icons/duotune/general/gen035.svg" />
                  </span>
                  Transfer to Another Folder
                </a>
              </p>
              <div>
                <button
                  tabindex="3"
                  type="submit"
                  :ref="
                    (el) => {
                      regenerateButtons[index] = el
                    }
                  "
                  :id="'regeneratePdfBtn-' + index"
                  class="btn btn-light-primary btn-sm"
                  @click="
                    regeneratePdf(
                      item.surveyId,
                      item.surveyType,
                      item.respondentId,
                      item.aspNetUserId,
                      index
                    )
                  "
                >
                  <span class="indicator-label">Regenerate PDF</span>

                  <span class="indicator-progress">
                    Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <!--end::Flex Container-->
        </div>
        <div v-else>
          <TableSkeleton></TableSkeleton>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
  <TransferSurveyModal
    :userRole="currentUser.role"
    :licensedDistributorId="0"
    :licensedDistAssocId="0"
    :clientId="currentClient.client.clientId"
    :folderId="folderid"
    :surveyType="foldername"
    :surveyId="selectedSurveyId"
    :respondentId="selectedRespondentId"
  ></TransferSurveyModal>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  watch,
  ComputedRef,
  onBeforeUpdate,
} from 'vue'

import { setCurrentPageTitle } from '@/core/helpers/breadcrumb'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useI18n } from 'vue-i18n'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import TransferSurveyModal from '@/components/modals/forms/TransferSurveyModal.vue'
import TableSkeleton from '@/components/widgets/skeletons/TableSkeleton.vue'
import { FolderRequest } from '../../core/types/FolderTypes'
import {
  FolderSurveyLineItemDto,
  RegenerateSurveyPdfRequestDto,
} from '../../core/types/SurveyTypes'
import {
  ExportBehavioralSurveyLocationRequestDto,
  ExportToExcelResponseDto,
} from '../../core/types/ExcelTypes'

export default defineComponent({
  name: 'client-survey-folder',
  props: {
    folderid: {
      type: [Number],
      default: 0,
    },
    foldername: {
      type: [String],
      default: '',
    },
    clientname: {
      type: [String],
      default: '',
    },
    clientlocationname: {
      type: [String],
      default: '',
    },
  },
  components: {
    TableSkeleton,
    TransferSurveyModal,
  },

  setup(props) {
    const store = useStore()
    const exportToExcelButton = ref<HTMLButtonElement | null>(null)
    const isDataReady = ref(false)
    const isExcelFileReady = ref(false)
    const currentUser = store.getters.currentUser
    let displayModal = ref<boolean>(false)
    const { d } = useI18n()
    const folderRequest = ref<FolderRequest>({
      currentUserRole: currentUser.role,
      currentUserId: currentUser.id,
      folderId: Number(props.folderid),
      folderName: props.foldername,
      getAllSurveys: false,
      clientId: 0,
      clientLocationId: 0,
    })
    const exportBehavioralSurveyLocationRequestDto = ref<
      ExportBehavioralSurveyLocationRequestDto
    >({
      lineItems: [],
      allBehavioralSurveys: false,
    })
    const excelFileResponse: ComputedRef<ExportToExcelResponseDto> = computed(
      () => {
        return store.getters.getExcelFile
      },
    )
    const folderLineItems = computed(() => {
      return store.getters.getFolderContents
    })
    const searchSurveys = ref('')

    const client = computed(() => {
      return store.getters.getClientByName(props.clientname)
    })
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo
    })
    if (props.folderid === 0) {
      folderRequest.value.folderId =
        currentClient.value.behavioralSurveyFolderId
      folderRequest.value.folderName = 'Behavioral-Surveys'
    }
    folderRequest.value.clientId = client.value.clientId

    const selectedSurveyId = ref(0)
    const selectedRespondentId = ref(0)

    const regenerateSurveyRequest = ref({} as RegenerateSurveyPdfRequestDto)
    const regenerateButtons = ref([] as HTMLButtonElement[])

    onMounted(() => {
      setCurrentPageTitle('Client Folder')
      setCurrentPageBreadcrumbs(props.clientname, ['Client'])
    })

    onBeforeUpdate(() => {
      regenerateButtons.value = []
    })

    store
      .dispatch(Actions.GET_CLIENT_FOLDER_CONTENTS, folderRequest.value)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'There was a problem retrieving folder contents.',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger',
          },
        })
      })

    const filterSurveys: ComputedRef<Array<FolderSurveyLineItemDto>> = computed(
      () => {
        return folderLineItems.value.filter((survey) => {
          return (
            survey.name
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.profileName
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.surveyStatus
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.jobTitle
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1
          )
        })
      }
    )

    watch(folderLineItems, () => {
      isDataReady.value = true
    })

    watch(excelFileResponse, () => {
      if (excelFileResponse.value.excelFilename !== 'na') {
        isExcelFileReady.value = true

        //Deactivate indicator
        exportToExcelButton.value?.removeAttribute('data-kt-indicator')
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = false
      } else if (excelFileResponse.value.excelFilename === 'na') {
        isExcelFileReady.value = false
      }
    })

    function exportToExcel() {
      exportBehavioralSurveyLocationRequestDto.value.lineItems =
        folderLineItems.value
      store.commit(Mutations.PURGE_EXCEL_FILE)

      store
        .dispatch(
          Actions.EXPORT_BEHAVIORAL_SURVEYS_TO_EXCEL,
          exportBehavioralSurveyLocationRequestDto.value
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'There was a problem with sync',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          })
        })

      if (exportToExcelButton.value) {
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = true
        // Activate indicator
        exportToExcelButton.value.setAttribute('data-kt-indicator', 'on')
      }
    }

    function selectedRow(surveyId, respondentId) {
      selectedSurveyId.value = surveyId
      selectedRespondentId.value = respondentId
    }

    function regeneratePdf(
      surveyId,
      surveyType,
      respondentId,
      aspNetUserId,
      index
    ) {
      regenerateSurveyRequest.value.respondentId = respondentId
      regenerateSurveyRequest.value.aspNetUserId = aspNetUserId
      regenerateSurveyRequest.value.surveyId = surveyId
      regenerateSurveyRequest.value.surveyType = surveyType

      regenerateButtons.value[index].disabled = true
      regenerateButtons.value[index].setAttribute('data-kt-indicator', 'on')

      store
        .dispatch(
          Actions.PUT_REGENERATE_SURVEY_PDF,
          regenerateSurveyRequest.value
        )
        .then(() => {
          regenerateButtons.value[index]?.removeAttribute('data-kt-indicator')
          regenerateButtons.value[index].disabled = false
          Swal.fire({
            text: 'Survey has been regenerated!',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok, great!',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        })
        .catch(() => {
          regenerateButtons.value[index]?.removeAttribute('data-kt-indicator')
          regenerateButtons.value[index].disabled = false
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          })
        })
    }

    return {
      currentUser,
      currentClient,
      client,
      isDataReady,
      isExcelFileReady,
      d,
      folderLineItems,
      searchSurveys,
      selectedSurveyId,
      selectedRespondentId,
      displayModal,
      filterSurveys,
      folderRequest,
      exportBehavioralSurveyLocationRequestDto,
      exportToExcel,
      excelFileResponse,
      exportToExcelButton,
      regeneratePdf,
      selectedRow,
      regenerateSurveyRequest,
      regenerateButtons,
    }
  },
})
</script>
